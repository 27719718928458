<template>
    <div>
        <div class="card w-100" v-if="sections.progress">
            <div class="p-3">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" :style="`width: ${sections.progress}%;`"
                         :aria-valuenow="parseInt(sections.progress)" aria-valuemin="0" aria-valuemax="100">
                        {{ sections.progress.replace('.', ',') }}%
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-32pt">
            <div class="card-header p-0 nav">
                <div class="row no-gutters" role="tablist">

                    <!--                    <div class="col-auto">-->
                    <!--                        <div-->
                    <!--                            @click="list_active = 'application_form'"-->
                    <!--                            class="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start pointer"-->
                    <!--                            :class="list_active === 'application_form' ? 'active' : ''"-->
                    <!--                        >-->
                    <!--                                    <span class="flex d-flex">-->
                    <!--                                        <strong>Formulário de Aplicação</strong>-->
                    <!--                                        <span class="ml-3 badge badge-notifications badge-primary text-white">{{ accountants.application_forms }}</span>-->
                    <!--                                    </span>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <div class="col-auto">
                        <div
                            @click="list_active = 'forms'"
                            class="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start pointer"
                            :class="list_active === 'forms' ? 'active' : ''"
                        >
                                    <span class="flex d-flex">
                                        <strong>Formulário de Módulo</strong>
                                        <span class="ml-3 badge badge-notifications badge-primary text-white">{{
                                                accountants.forms
                                            }}</span>
                                    </span>
                        </div>
                    </div>

                    <div class="col-auto">
                        <div
                            @click="list_active = 'schedule'"
                            class="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start pointer"
                            :class="list_active === 'schedule' ? 'active' : ''"
                        >
                                    <span class="flex d-flex">
                                        <strong>Agenda</strong>
                                        <span class="ml-3 badge badge-notifications badge-primary text-white">{{
                                                accountants.schedules
                                            }}</span>
                                    </span>
                        </div>
                    </div>

                    <div class="col-auto">
                        <div
                            @click="list_active = 'activity'"
                            class="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start pointer"
                            :class="list_active === 'activity' ? 'active' : ''"
                        >
                                    <span class="d-flex">
                                        <strong>Atividade</strong>
                                        <span class="ml-3 badge badge-notifications badge-primary text-white">{{
                                                accountants.activities
                                            }}</span>
                                    </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body">


                <ActivitiesTables v-show="list_active === 'forms'" session="FormDashboard"/>
                <ActivitiesTables v-show="list_active === 'schedule'" session="ScheduleDashboard"/>
                <ActivitiesTables v-show="list_active === 'activity'" session="ActivityDashboard"/>

            </div>


        </div>

        <ListModules
            :modules="sections"
        />

    </div>
</template>

<script>

import http from '@/http'
import ListModules from "@/views/mentee/list-modules.vue";
import ActivitiesTables from "@/views/dashboard/activities-tables.vue";

export default {
    components: {
        ActivitiesTables,
        ListModules,
    },
    data() {
        return {
            sections: [],
            mentee: this.$route.params.mentee,
            slug: this.$route.params.slug,
            completed: 0,
            total: 1,
            list_active: 'forms',
            accountants: {
                forms: 0,
                application_forms: 0,
                schedules: 0,
                activities: 0
            },
        }
    },

    methods: {
        async getData() {

            const mentee = this.$route.params.mentee;
            const mentoring = this.$route.params.mentoring;

            const formData = {
                mentee,
                id: mentoring
            }
            http.post('mentorings/tasks-by-mentee/', formData)
                .then(response => {
                    this.sections = response.data.mentoring.sections;
                    this.sections.progress = response.data.progress;
                    this.$store.commit('mentee/setTasks', {
                        sections: this.sections,
                        mentoring,
                        mentee
                    });
                })
                .catch(e => {
                    console.error('Get Tasks Mentoring: ', e)
                })
        },

        getAccountants() {
            const id = this.$route.params.mentee
            http.get('tasks/accountants?mentee_id=' + id)
                .then(response => {
                    this.accountants = response.data;
                })
                .catch(e => {
                    console.error('get Accountants: ', e);
                })
        },

        setTasks(section) {
            const formData = {
                mentee: this.$route.params.mentee,
                id: this.$route.params.mentoring,
                section,
            }

            http.post('tasks/generate', formData)
                .then(() => {
                    this.$notifySuccess('Sessão liberada com sucesso!');
                    this.getData();
                    this.$emit('refresh-graphic');
                })
                .catch(e => {
                    console.error('Get Tasks Mentoring: ', e)
                })
        }
    },

    mounted() {
        this.getData();
        this.getAccountants();
    }
}

</script>
